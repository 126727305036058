import * as React from 'react';

function HourglassIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 351 519.52" {...props}>
      <circle cx={174} cy={353.75} r={10} />
      <path d="M304 457.75v-54c0-56.09-35.86-107.76-87.2-125.65-7.78-2.71-12.8-9.52-12.8-17.35 0-7.9 5.18-14.7 12.8-17.36 52.16-18.17 87.2-67.46 87.2-122.64v-57c22.6-4.57 40-24.6 40-49 0-5.52-4.48-10-10-10H14c-5.52 0-10 4.48-10 10C4 38.9 21.21 59.1 44 63.74v57.01c0 55.19 35.04 104.48 87.2 122.65 7.77 2.71 12.8 9.52 12.8 17.35 0 7.9-5.18 14.7-12.8 17.36C79.86 295.99 44 347.66 44 403.75v54.01c-22.8 4.65-40 24.85-40 48.99 0 5.52 4.48 10 10 10h320c5.52 0 10-4.48 10-10 0-13.35-5.2-25.9-14.65-35.35-7.04-7.04-15.81-11.72-25.35-13.65zM25.71 24.65H322.3c-4.19 11.88-15.3 20.1-28.3 20.1H54c-13.04 0-24.16-8.46-28.29-20.1zM64 403.75c0-47.73 30.34-91.62 73.78-106.76 15.61-5.44 26.22-19.77 26.22-36.24 0-16.22-10.54-30.78-26.22-36.24C93.65 209.13 64 167.44 64 120.75v-56h220v56c0 46.69-29.65 88.38-73.78 103.76-15.61 5.44-26.22 19.77-26.22 36.24 0 16.22 10.54 30.78 26.22 36.24C253.66 312.13 284 356.02 284 403.75v53h-20.62c-4.47-35.54-32.91-48.53-59.74-57.88-8.44-2.93-16.25-7.82-22.56-14.15-1.88-1.88-4.42-2.93-7.08-2.93s-5.2 1.06-7.08 2.93c-6.4 6.41-13.99 11.17-22.56 14.15-26.82 9.34-55.27 22.33-59.74 57.87H64v-52.99zm179.14 53H104.86c3.73-20.3 19.64-29.78 46.08-38.98 8.31-2.89 16.14-7.2 23.06-12.63 6.93 5.44 14.77 9.75 23.07 12.63 26.43 9.2 42.34 18.68 46.07 38.98zm-217.43 40c4.13-11.64 15.25-20 28.29-20h240c8.01 0 15.54 3.12 21.21 8.79 3.22 3.22 5.62 7.04 7.09 11.21H25.71z" />
      <path d="M174 222.71c2.65 0 5.2-1.06 7.08-2.93 6.4-6.41 13.99-11.17 22.56-14.15 17.72-6.17 32.84-17.52 43.72-32.83 10.89-15.31 16.64-33.31 16.64-52.04 0-3.47-1.8-6.68-4.74-8.51a10.007 10.007 0 00-9.73-.44c-23.32 11.66-49.44 17.83-75.53 17.83-26.09 0-52.21-6.16-75.53-17.82a9.98 9.98 0 00-9.73.44 9.991 9.991 0 00-4.74 8.51c0 38.19 24.25 72.29 60.36 84.88 8.44 2.93 16.25 7.82 22.56 14.15 1.88 1.85 4.43 2.91 7.08 2.91zm-68.1-85.79c21.66 8.35 44.89 12.71 68.1 12.71 23.21 0 46.45-4.36 68.11-12.72-2.06 8.66-5.78 16.88-11.05 24.29-8.46 11.9-20.22 20.73-34 25.53-8.31 2.89-16.14 7.2-23.06 12.63-6.93-5.44-14.77-9.75-23.07-12.63-22.84-7.95-39.59-27-45.03-49.81zM174 323.75c5.52 0 10-4.48 10-10v-23c0-5.52-4.48-10-10-10s-10 4.48-10 10v23c0 5.52 4.48 10 10 10z" />
    </svg>
  );
}

export { HourglassIcon };
