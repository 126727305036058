import * as React from 'react';

function TrophyIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="-46 0 512 512" {...props}>
      <path d="M170 412c5.52 0 10-4.48 10-10s-4.48-10-10-10-10 4.48-10 10 4.48 10 10 10zm0 0" />
      <path d="M0 70c0 78.145 53.188 145.414 127.355 164.605a227.03 227.03 0 0032.649 34.254c6.351 5.399 9.996 13.856 9.996 23.2V352h-20c-16.543 0-30 13.457-30 30v70h-10c-16.543 0-30 13.457-30 30v20c0 5.523 4.477 10 10 10h240c5.52 0 10-4.477 10-10v-20c0-16.543-13.46-30-30-30h-10v-70c0-16.543-13.46-30-30-30h-20v-59.941c0-9.344 3.64-17.801 9.996-23.2a227.223 227.223 0 0032.649-34.254C366.809 215.415 420 148.148 420 70V50c0-16.543-13.457-30-30-30h-50V10c0-5.523-4.477-10-10-10H90c-5.523 0-10 4.477-10 10v10H30C13.457 20 0 33.457 0 50zm310 402c5.516 0 10 4.484 10 10v10H100v-10c0-5.516 4.484-10 10-10zm-40-100c5.516 0 10 4.484 10 10v70H140v-70c0-5.516 4.484-10 10-10zm70-276.16V40h50c5.516 0 10 4.484 10 10v20c0 60.52-35.543 113.617-89.152 137.21C329.988 173.337 340 135.349 340 95.84zM100 20h220v75.84c0 45.008-14.234 87.855-41.164 123.918a206.956 206.956 0 01-31.793 33.86C236.211 262.823 230 276.835 230 292.057V352h-40v-59.941c0-15.223-6.21-29.235-17.043-38.438a207.139 207.139 0 01-31.793-33.863C114.234 183.695 100 140.848 100 95.84zM20 50c0-5.516 4.484-10 10-10h50v55.84c0 39.508 10.012 77.5 29.152 111.37C55.547 183.618 20 130.52 20 70zm0 0" />
      <path d="M210 412h40c5.523 0 10-4.477 10-10s-4.477-10-10-10h-40c-5.523 0-10 4.477-10 10s4.477 10 10 10zm-36.387-288.02l-4.988 29.098a10.002 10.002 0 003.977 9.781 9.993 9.993 0 0010.53.762l26.145-13.734 26.141 13.734c3.371 1.774 7.453 1.477 10.531-.762s4.621-6.03 3.977-9.78l-4.988-29.099 21.144-20.62a10 10 0 00-5.543-17.055l-29.226-4.246-13.063-26.485a10.002 10.002 0 00-17.937 0L187.238 82.06l-29.226 4.246a9.994 9.994 0 00-8.07 6.804 9.987 9.987 0 002.527 10.25zm21.703-22.882a10.009 10.009 0 007.532-5.473l6.43-13.027 6.421 13.027a10.009 10.009 0 007.531 5.473l14.383 2.086-10.402 10.144a9.991 9.991 0 00-2.875 8.852l2.453 14.312-12.855-6.754a9.998 9.998 0 00-9.305 0l-12.867 6.758 2.457-14.316a10.016 10.016 0 00-2.875-8.852l-10.406-10.144zm0 0" />
    </svg>
  );
}

export { TrophyIcon };
