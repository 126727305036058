import * as React from 'react';

function BalloonIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 480 480" {...props}>
      <path d="M240 0c-66.243.075-119.925 53.757-120 120v4.856a119.942 119.942 0 0041.408 90.68l71.488 61.96A23.855 23.855 0 00224 296v24h16c0 17.673 14.327 32 32 32 8.837 0 16 7.163 16 16s-7.163 16-16 16h-64c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32h16c8.837 0 16 7.163 16 16v16h16v-16c0-17.673-14.327-32-32-32h-16c-8.837 0-16-7.163-16-16s7.163-16 16-16h64c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32-8.837 0-16-7.163-16-16h16v-24a23.895 23.895 0 00-9.6-19.08l62.456-62.456A119.2 119.2 0 00360 129.6V120C359.925 53.757 306.243.075 240 0zm16 304h-16v-8a8 8 0 0116 0v8zm88-174.4a103.318 103.318 0 01-30.464 73.536l-65.928 65.928-75.72-65.6A103.921 103.921 0 01136 124.856V120c0-57.438 46.562-104 104-104s104 46.562 104 104v9.6z" />
      <path d="M240 32v16c39.746.044 71.956 32.254 72 72h16c-.053-48.579-39.421-87.947-88-88zm72 104h16v16h-16z" />
    </svg>
  );
}

export { BalloonIcon };
