import * as React from 'react';

interface ISideBySide {
  children: React.ReactNode;
  isReversed?: boolean;
  sideComponent?: React.ReactNode;
}

function SideBySide({
  children,
  isReversed = true,
  sideComponent,
}: ISideBySide): React.ReactElement {
  return (
    <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
      <article className="grid gap-8 lg:grid-cols-5">
        {!isReversed && sideComponent ? (
          <SideComponent>{sideComponent}</SideComponent>
        ) : null}
        <div className="flex flex-col justify-center lg:col-span-2">
          {children}
        </div>
        {isReversed && sideComponent ? (
          <SideComponent>{sideComponent}</SideComponent>
        ) : null}
      </article>
    </div>
  );
}

interface ISideComponent {
  children: React.ReactNode;
}

function SideComponent({ children }: ISideComponent): React.ReactElement {
  return (
    <div className="relative order-last lg:col-span-3 lg:order-none">
      <div className="lg:h-full lg:grid">{children}</div>
    </div>
  );
}

export { SideBySide };
