import 'keen-slider/keen-slider.min.css';

import { useKeenSlider } from 'keen-slider/react';
import * as React from 'react';

function preventNavigation(event: TouchEvent) {
  // Center point of the touch area
  const touchXPosition = event.touches[0].pageX;
  // Size of the touch area
  const touchXRadius = event.touches[0].radiusX || 0;

  // We set a threshold (10px) on both sizes of the screen,
  // if the touch area overlaps with the screen edges
  // it's likely to trigger the navigation. We prevent the
  // touchstart event in that case.
  if (
    touchXPosition - touchXRadius < 10 ||
    touchXPosition + touchXRadius > window.innerWidth - 10
  )
    event.preventDefault();
}

interface ICarousel {
  children: React.ReactNode;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function Carousel({ children }: ICarousel): React.ReactElement {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isMounted, setIsMounted] = React.useState(false);
  const [pause, setPause] = React.useState(false);
  const sliderContainerRef = React.useRef<HTMLDivElement>(null);
  const timer = React.useRef(0);
  let test = Array.from({ length: React.Children.count(children) });

  const [sliderRef, slider] = useKeenSlider<HTMLUListElement>({
    loop: true,
    slidesPerView: 1,
    duration: 1500,
    mounted: () => {
      setIsMounted(true);
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
  });

  // Stop the history navigation gesture on touch devices
  React.useEffect(() => {
    sliderContainerRef.current?.addEventListener(
      'touchstart',
      preventNavigation,
      { passive: true }
    );
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sliderContainerRef.current?.removeEventListener(
        'touchstart',
        preventNavigation
      );
    };
  }, []);

  // Automatically slide through CTAs
  React.useEffect(() => {
    timer.current = window.setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 3500);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  // Pause slider when hovering over
  React.useEffect(() => {
    sliderRef?.current?.addEventListener('mouseover', () => {
      setPause(true);
    });
    sliderRef?.current?.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [sliderRef]);

  // Allow control of carousel with keyboard when focused
  function handleKeyDown(e) {
    if (e.key === 'ArrowLeft') slider.prev();
    if (e.key === 'ArrowRight') slider.next();
  }

  return (
    <div
      ref={sliderContainerRef}
      onKeyDown={(e) => handleKeyDown(e)}
      tabIndex={0}
      role="region"
      className="relative bg-blue-dark focus:z-10"
    >
      <ul
        ref={sliderRef}
        className="h-full transition-opacity duration-150 keen-slider max-h-[36rem]"
        style={{ opacity: isMounted ? 1 : 0 }}
      >
        {React.Children.map(children, (child) => {
          // Add the keen-slider__slide className to children
          if (React.isValidElement(child)) {
            return {
              ...child,
              props: {
                ...child.props,
                className: `${
                  child.props.className
                    ? `${String(child.props.className)} `
                    : ''
                }keen-slider__slide`,
              },
            };
          }
          return child;
        })}
      </ul>
      {slider && (
        <div className="absolute inset-x-0 z-10 transform bottom-2">
          <ul className="relative flex items-center justify-center space-x-2">
            {test.map((e, index) => (
              <li key={index}>
                <button
                  type="button"
                  aria-label={`Move to slide ${index + 1}`}
                  onClick={() => {
                    slider.moveToSlideRelative(index);
                  }}
                  className={`${
                    currentSlide !== index ? 'bg-opacity-0' : 'bg-opacity-100'
                  } bg-white h-2.5 w-2.5 rounded-full border border-white transition duration-150 ease-in-out shadow-md pointer-events-auto`}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export { Carousel };
