// Keep all static queries in this file
// Add new queries as necessary
// (you add aliases if you need to access the same node more than once)
// To use:
// import useGraphql from './src/hooks/use-graphql.js'
// const { site } = useGraphql();
// return <h1>{site.siteMetadata.title}</h1>;

import { graphql, useStaticQuery } from 'gatsby';

export const useGraphQL = () =>
  useStaticQuery(
    graphql`
      {
        secondBanner: file(relativePath: { eq: "second-banner2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        agmNotice: file(relativePath: { eq: "agm_notice.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        agm2Notice: file(relativePath: { eq: "agm2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        pcbRewards1: file(relativePath: { eq: "pcb_rewards_1.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        pcbRewards2: file(relativePath: { eq: "pcb_rewards_2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        electionNotice: file(relativePath: { eq: "election_notice.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        gamblingBanner: file(relativePath: { eq: "gambling_banner.png" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bgTexture: file(relativePath: { eq: "background-texture.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        barefootbowls1: file(relativePath: { eq: "barefootbowls1.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        barefootbowls2: file(relativePath: { eq: "barefootbowls2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bowlsProgram1: file(relativePath: { eq: "bowls-program-1.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bowlsProgram2: file(relativePath: { eq: "bowls-program-3.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bowlsProgram3: file(relativePath: { eq: "bowls-program-2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bowlsUpcomingTournaments: file(
          relativePath: { eq: "upcoming-tournaments.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        directorsImage: file(relativePath: { eq: "club-directors.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        driftBarOpeningHours: file(
          relativePath: { eq: "drift-bar-opening-hours-new.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        driftBar: file(relativePath: { eq: "drift-bar.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        eatAndDrinkDriftBar: file(
          relativePath: { eq: "eat-and-drink-drift-bar.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        eatAndDrinkWines: file(
          relativePath: { eq: "eat-and-drink-wines.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        cocktails: file(
          relativePath: { eq: "cocktails.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        functions: file(relativePath: { eq: "functions.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        functionHavingAFunction: file(
          relativePath: { eq: "having-a-function.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        functionPackages: file(relativePath: { eq: "function-packages.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        functionAd: file(relativePath: { eq: "function-ad2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        happyHourSection: file(relativePath: { eq: "happy-hour-section.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        landingBingo: file(relativePath: { eq: "landing-bingo.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        landingRaffle: file(relativePath: { eq: "landing-raffle.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        landingWeekendVibes: file(
          relativePath: { eq: "landing-weekend-vibes.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        landingEatDrink: file(relativePath: { eq: "landing-eat-drink-new.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        landingFunction: file(relativePath: { eq: "landing-function.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        landingMemberBenefits: file(
          relativePath: { eq: "member-benefits.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        liveEntertainment: file(
          relativePath: { eq: "live-entertainment.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        liveSport: file(relativePath: { eq: "live-sport2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        membership: file(relativePath: { eq: "membership.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        pointsPlus: file(relativePath: { eq: "points-plus.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ourStory1: file(relativePath: { eq: "our-story1.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ourStory2: file(relativePath: { eq: "our-story2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ourStory3: file(relativePath: { eq: "our-story3.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        pokerImage: file(relativePath: { eq: "poker.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        seeOurWines: file(relativePath: { eq: "wine-pour.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        tabHourOfPower: file(relativePath: { eq: "tab-hour-of-power.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whatsOn: file(relativePath: { eq: "landing-whats-on.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whatsOnHero: file(relativePath: { eq: "reception.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whatsOnEvents: file(relativePath: { eq: "whats-on-events.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whatsOnEntertainment: file(
          relativePath: { eq: "whats-on-entertainment.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whatsOnPromotions: file(
          relativePath: { eq: "whats-on-promotions.jpg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        onlineTakeaway: file(
          relativePath: { eq: "drift-online-takeaway.jpeg" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
