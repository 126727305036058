/* eslint-disable sonarjs/no-duplicate-string */
import SanityBlockContent from '@sanity/block-content-to-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as React from 'react';

import { BackgroundTexture } from './background-texture';
import { HeadingWithIcon } from './heading-with-icon';
import { ElectricGuitarIcon } from './vectors';

dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);

function ThirdBanner(): React.ReactElement {
  const gigsQuery = useStaticQuery(graphql`
    {
      allSanityThirdBanner {
        nodes {
          subHeading
          _rawDescription
          bannerImage {
            asset {
              _id
            }
          }
          gigs {
            _key
            title
            startsAt
            endsAt
          }
        }
      }
    }
  `);

  const gigs = gigsQuery.allSanityThirdBanner?.nodes[0]?.gigs;

  const gigsFiltered = gigs.filter((gig) =>
    dayjs(dayjs().format('YYYY-MM-DD')).isSameOrBefore(
      dayjs(gig.endsAt).format('YYYY-MM-DD')
    )
  );

  const gigsSortedByDate = gigsFiltered.sort(
    (
      a: { startsAt: string | number | Date },
      b: { startsAt: string | number | Date }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => (new Date(a.startsAt) as any) - (new Date(b.startsAt) as any)
  );

  const imageData = getGatsbyImageData(
    gigsQuery.allSanityThirdBanner?.nodes[0]?.bannerImage?.asset,
    { width: 750, placeholder: 'dominantColor' },
    { projectId: 'q45hh4q1', dataset: 'production' }
  );

  return (
    <div id="live_music" className="relative py-12 scroll-margin-top-24">
      <BackgroundTexture />
      <article className="relative grid w-full gap-8 px-4 mx-auto lg:grid-cols-5 max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="flex flex-col justify-center w-full mx-auto lg:col-span-2 max-w-prose">
          {/* <HeadingWithIcon>
            <HeadingWithIcon.Heading>
              More
              <br />
              Events
            </HeadingWithIcon.Heading>
            <HeadingWithIcon.Icon hidden>
              <ElectricGuitarIcon />
            </HeadingWithIcon.Icon>
          </HeadingWithIcon> */}
          <h3 className="mt-4 heading-3">
            {gigsQuery.allSanityThirdBanner?.nodes[0]?.subHeading}
          </h3>
          <div className="prose">
            <SanityBlockContent
              renderContainerOnSingleChild
              blocks={gigsQuery.allSanityThirdBanner?.nodes[0]?._rawDescription}
              className="prose"
            />
          </div>
          <ul className="mt-4">
            {gigsSortedByDate.map((gig, index) => {
              if (
                dayjs(dayjs().format('YYYY-MM-DD')).isSameOrBefore(
                  dayjs(gig.endsAt).format('YYYY-MM-DD')
                )
              ) {
                return (
                  <li
                    key={gig._key}
                    className={`py-4 text-lg uppercase ${
                      index !== gigsSortedByDate.length - 1
                        ? 'border-b border-black'
                        : ''
                    }`}
                  >
                    {dayjs(gig.startsAt).format('ddd')}{' '}
                    {dayjs(gig.startsAt).format('MMM')}{' '}
                    {dayjs(gig.startsAt).format('D')}{' '}
                    {dayjs(gig.startsAt).format('h.mm')} -{' '}
                    {dayjs(gig.endsAt).format('h.mm')}
                    <strong className="ml-1 text-blue-dark">
                      {gig.title}{' '}
                      {dayjs(
                        dayjs(gig.endsAt).format('YYYY-MM-DD')
                      ).isToday() && 'today'}
                    </strong>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
        <div className="grid gap-8 lg:col-span-3">
          <GatsbyImage
            image={imageData as IGatsbyImageData}
            className="flex-1 w-full"
            alt="gig banner"
            objectFit="contain"
          />
        </div>
      </article>
    </div>
  );
}

export { ThirdBanner };
