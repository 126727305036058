/* eslint-disable react/jsx-one-expression-per-line */
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'gatsby';
import * as React from 'react';
import { HiMenu } from 'react-icons/hi';

import config from '../../config.json';
import { Logo } from '../svg/logo';
import { MobileMenu } from './mobile-menu';

function Nav(): React.ReactElement {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return (
    <header className="sticky top-0 z-30 w-full bg-white">
      <div className="w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-8">
          <div className="flex justify-start flex-1">
            <Link to="/">
              <div className="sr-only">{config.title}</div>
              <Logo className="w-auto h-12 xl:h-20" />
            </Link>
          </div>
          <div className="hidden md:flex-col md:items-end md:justify-between md:space-y-6 md:flex">
            <div className="space-x-4">
              <a
                href={`tel:${config.phone}`}
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white uppercase transition duration-150 ease-in-out border border-transparent hover:bg-black bg-blue-dark"
              >
                Call us on {config.phone}
              </a>
              <a
                href="https://bookings.nowbookit.com/?accountid=bb2dc809-4b9c-4bff-b262-c0ba1109cb80&venueid=4012&theme=light&colors=hex,0b2e4a,a42331"
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white uppercase transition duration-150 ease-in-out bg-red-800 border border-transparent hover:bg-black"
                target="_blank"
                rel="nofollow"
              >
                Book Table Now
              </a>
            </div>

            {/* https://www.mryum.com/port-city */}
            <nav className="flex-shrink-0 hidden space-x-8 xl:flex">
              {config.siteNavigation.map((menuItem) => (
                <MenuItem key={menuItem.label} menuItem={menuItem} />
              ))}
            </nav>
          </div>
          <div className="-my-2 -mr-2 xl:hidden">
            <button
              type="button"
              onClick={open}
              className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset"
            >
              <span className="sr-only">Open menu</span>
              <HiMenu aria-hidden className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} close={close} />
    </header>
  );
}

function MenuItem({ menuItem }) {
  const { slug, label, submenu } = menuItem;
  if (submenu) {
    return <Submenu label={label} submenu={submenu} />;
  }
  return (
    <div className="relative">
      <Link
        to={slug}
        className="text-base font-medium text-gray-500 uppercase whitespace-nowrap hover:text-blue-dark"
      >
        {label}
      </Link>
    </div>
  );
}

type SubmenuProps = {
  submenu: Array<{ slug: string; label: string; color: string }>;
  label: string;
};

function Submenu({ submenu, label }: SubmenuProps) {
  return (
    <div className="relative inline-block">
      <Menu>
        {({ open }) => (
          <>
            <span className="">
              <Menu.Button className="inline-flex justify-center w-full text-base font-medium text-gray-500 uppercase transition duration-150 ease-in-out whitespace-nowrap focus:outline-none active:bg-gray-50 active:text-gray-800 hover:text-blue-dark">
                <span>{label}</span>
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Menu.Button>
            </span>
            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute w-56 mt-2 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 shadow-lg outline-none -left-4 focus:ring-0"
              >
                <div className="py-1">
                  {submenu.map((sub) => (
                    <Menu.Item key={sub.label}>
                      {({ active }) => (
                        <Link
                          to={sub.slug}
                          className={`${
                            active
                              ? 'bg-blue-dark text-white'
                              : sub.color
                              ? 'text-white '
                              : 'text-gray-700'
                          } flex justify-between w-full px-4 py-2 text-sm tracking-wider text-left uppercase transition duration-150 ease-in-out ${
                            sub.color ? `${sub.color}` : ''
                          }`}
                        >
                          {sub.label}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export { Nav };
