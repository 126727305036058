import * as React from 'react';

function CalendarIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
      <path d="M488 355.335V70.5c0-16.818-13.683-30.5-30.5-30.5H385V30c0-16.542-13.458-30-30-30s-30 13.458-30 30v10H163V30c0-16.542-13.458-30-30-30s-30 13.458-30 30v10H30.5C13.683 40 0 53.682 0 70.5v371C0 458.318 13.683 472 30.5 472h136.301c5.522 0 10-4.477 10-10s-4.478-10-10-10H30.5c-5.79 0-10.5-4.71-10.5-10.5v-371C20 64.71 24.71 60 30.5 60H103v10c0 16.542 13.458 30 30 30s30-13.458 30-30V60h162v10c0 16.542 13.458 30 30 30s30-13.458 30-30V60h72.5c5.79 0 10.5 4.71 10.5 10.5V120H90c-5.522 0-10 4.477-10 10s4.478 10 10 10h378v198.437a93.486 93.486 0 00-28-11.831V178c0-5.523-4.478-10-10-10H58c-5.522 0-10 4.477-10 10v236c0 5.523 4.478 10 10 10h266.197a93.284 93.284 0 006.169 28H246.8c-5.522 0-10 4.477-10 10s4.478 10 10 10h94.303c17.027 24.174 45.147 40 76.897 40 51.832 0 94-42.168 94-94 0-24.054-9.086-46.022-24-62.665zM143 70c0 5.514-4.486 10-10 10s-10-4.486-10-10V30c0-5.514 4.486-10 10-10s10 4.486 10 10v40zm222 0c0 5.514-4.486 10-10 10s-10-4.486-10-10V30c0-5.514 4.486-10 10-10s10 4.486 10 10v40zm53 254a94.455 94.455 0 00-13.69 1H365.6v-58H420v57.025a95.263 95.263 0 00-2-.025zm-92.957 80H291.2v-59h54.4v13.114c-10.663 12.869-17.967 28.617-20.557 45.886zM68 267h54.4v58H68v-58zm74.4 0h54.4v58h-54.4v-58zm148.8 0h54.4v58h-54.4v-58zm-20 58h-54.4v-58h54.4v58zm20-78v-59h54.4v59h-54.4zm-20 0h-54.4v-59h54.4v59zm-74.4 0h-54.4v-59h54.4v59zm0 98v59h-54.4v-59h54.4zm20 0h54.4v59h-54.4v-59zM420 247h-54.4v-59H420v59zm-297.6-59v59H68v-59h54.4zM68 345h54.4v59H68v-59zm350 147c-40.804 0-74-33.196-74-74s33.196-74 74-74 74 33.196 74 74-33.196 74-74 74z" />
      <path d="M446.929 393.762L416 424.691l-13.762-13.762c-3.904-3.905-10.234-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.142l20.833 20.833a10.002 10.002 0 0014.142 0l38-38c3.905-3.905 3.905-10.237 0-14.143-3.905-3.904-10.235-3.904-14.141.001zM206.8 452c-2.63 0-5.21 1.07-7.07 2.93a10.08 10.08 0 00-2.93 7.07c0 2.63 1.07 5.21 2.93 7.07 1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93a10.08 10.08 0 002.93-7.07c0-2.63-1.07-5.21-2.93-7.07a10.08 10.08 0 00-7.07-2.93zM57.069 137.07A10.07 10.07 0 0060 130c0-2.63-1.07-5.21-2.931-7.07A10.072 10.072 0 0050 120c-2.641 0-5.21 1.07-7.07 2.93A10.077 10.077 0 0040 130c0 2.63 1.069 5.21 2.93 7.07A10.058 10.058 0 0050 140c2.63 0 5.21-1.07 7.069-2.93z" />
    </svg>
  );
}

export { CalendarIcon };
