import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useGraphQL } from '../hooks';

interface IBackgroundTexture {
  insetTop?: 'none' | 'half' | 'full';
  insetBottom?: 'none' | 'half' | 'full';
  overflowTop?: boolean;
  overflowBottom?: boolean;
}

function BackgroundTexture({
  insetTop,
  insetBottom,
  overflowTop = false,
  overflowBottom = false,
}: IBackgroundTexture): React.ReactElement {
  const { bgTexture } = useGraphQL();
  return (
    <div
      aria-hidden
      className={`absolute inset-0 flex${insetTop === 'half' ? ' pt-12' : ''}${
        insetTop === 'full' ? ' pt-24' : ''
      }${insetBottom === 'half' ? ' pb-12' : ''}${
        insetBottom === 'full' ? ' pb-24' : ''
      }${overflowTop ? ' transform -translate-y-24' : ''}${
        overflowBottom ? ' transform translate-y-24' : ''
      }`}
    >
      <GatsbyImage
        image={bgTexture.childImageSharp.gatsbyImageData}
        className="flex-1"
        alt=" "
      />
    </div>
  );
}

export { BackgroundTexture };
