import * as React from 'react';

import config from '../../config.json';
import { Footer } from './footer';
import { MembershipBanner } from './membership-banner';
import { OnlineTakeawayModal } from './online-takeaway-modal';
import { Nav } from './nav';

interface LayoutProps {
  children: React.ReactNode;
  hero?: React.ReactNode;
}

function Layout({ children, hero }: LayoutProps): React.ReactElement {
  return (
    <div className="relative flex flex-col min-h-screen font-sans antialiased text-gray-700 bg-white fill-available">
      {hero || null}
      <Nav />
      <main className="flex flex-col flex-1">{children}</main>
      <Footer />
      {/* <OnlineTakeawayModal /> */}
      {/* <MembershipBanner /> */}
    </div>
  );
}

export { Layout };
