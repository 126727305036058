import * as React from 'react';

function BusinessCardIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 530 394" {...props}>
      <path d="M465 11.52H53c-27.57 0-50 22.43-50 50v280c0 27.57 22.43 50 50 50h412c27.57 0 50-22.43 50-50v-280c0-27.57-22.43-50-50-50zm30 330c0 16.54-13.46 30-30 30H53c-16.54 0-30-13.46-30-30v-280c0-16.54 13.46-30 30-30h412c16.54 0 30 13.46 30 30v280z" />
      <path d="M259 255.52c52.93 0 96-43.07 96-96s-43.07-96-96-96-96 43.07-96 96 43.07 96 96 96zm0-20c-16.14 0-31.11-5.07-43.42-13.68 7.2-17.32 24.23-28.94 43.42-28.94s36.22 11.62 43.43 28.94c-12.32 8.61-27.29 13.68-43.43 13.68zm-18.2-80.82v-3.76c0-10.04 8.16-18.2 18.2-18.2s18.2 8.16 18.2 18.2v3.76c0 10.03-8.16 18.2-18.2 18.2s-18.2-8.17-18.2-18.2zM259 83.52c41.91 0 76 34.09 76 76 0 18.25-6.47 35.02-17.23 48.13-6.77-12.33-17.24-22.13-29.75-28.14 5.72-6.68 9.18-15.34 9.18-24.8v-3.76c0-21.06-17.14-38.2-38.2-38.2s-38.2 17.13-38.2 38.2v3.76c0 9.46 3.46 18.12 9.18 24.8-12.51 6.01-22.98 15.82-29.75 28.14-10.76-13.12-17.23-29.88-17.23-48.13 0-41.91 34.09-76 76-76zM380.17 275.6H137.83c-5.52 0-10 4.48-10 10s4.48 10 10 10h242.34c5.52 0 10-4.48 10-10s-4.48-10-10-10zm-75.5 40h-91.34c-5.52 0-10 4.48-10 10s4.48 10 10 10h91.34c5.52 0 10-4.48 10-10s-4.48-10-10-10z" />
    </svg>
  );
}

export { BusinessCardIcon };
