import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { ImageNode } from 'gatsby-source-sanity/lib-es5/images/getGatsbyImageProps';
import * as React from 'react';

import { HeadingWithIcon } from './heading-with-icon';
import { SideBySide } from './side-by-side';
import { SideImage } from './side-image';
import { PartyIcon } from './vectors/party';

type Events = {
  nodes: [
    {
      bannerImage: {
        asset: ImageNode;
      };
      events: Array<{
        id: string;
        copy: [];
        title: string;
      }>;
    }
  ];
};

function UpcomingEvents(): React.ReactElement {
  const { allSanityUpcomingEvents } = useStaticQuery(graphql`
    {
      allSanityUpcomingEvents {
        nodes {
          bannerImage {
            asset {
              _id
            }
          }
          displaySecondBanner
          secondBannerImage {
            asset {
              _id
            }
          }
          events {
            id: _key
            _rawCopy
            title
          }
        }
      }
    }
  `);

  const events = allSanityUpcomingEvents?.nodes[0]?.events;
  console.log('EVENTS= ', events);

  return (
    <div id="upcoming_events" className="relative py-12 scroll-margin-top-24">
      <SideBySide
        isReversed={false}
        sideComponent={
          allSanityUpcomingEvents?.nodes[0]?.bannerImage ? (
            <SideImage
              showSecondImage={
                allSanityUpcomingEvents?.nodes[0].displaySecondBanner
              }
              secondImage={
                getGatsbyImageData(
                  allSanityUpcomingEvents?.nodes[0]?.secondBannerImage?.asset,
                  { width: 750, placeholder: 'dominantColor' },
                  { projectId: 'q45hh4q1', dataset: 'production' }
                ) as IGatsbyImageData
              }
              image={
                getGatsbyImageData(
                  allSanityUpcomingEvents?.nodes[0]?.bannerImage.asset,
                  { width: 750, placeholder: 'dominantColor' },
                  { projectId: 'q45hh4q1', dataset: 'production' }
                ) as IGatsbyImageData
              }
              objectFit="contain"
            />
          ) : null
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Upcoming
                <br />
                Events
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <PartyIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-8 space-y-8">
              {events.map((e) => {
                console.log(e._rawCopy);
                return (
                  <div key={e.id}>
                    <h3 className="text-lg font-semibold uppercase">
                      {e.title}
                    </h3>
                    <SanityBlockContent
                      projectId={'q45hh4q1'}
                      dataset={'production'}
                      blocks={e._rawCopy}
                      renderContainerOnSingleChild
                      className="mt-5 prose max-w-none prose-on-dark"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

export { UpcomingEvents };
