import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { BackgroundTexture } from '../background-texture';

interface IWine {
  _key: string;
  name: string;
  items: Array<{
    _key: string;
    name: string;
    origin: string;

    memberPrice: {
      glass1: string | null;
      glass2: string | null;
      bottle: string | null;
    };
    nonMemberPrice: {
      glass1: string | null;
      glass2: string | null;
      bottle: string | null;
    };
  }>;
}

function WinesMenu(): React.ReactElement {
  const winesQuery = useStaticQuery(graphql`
    {
      allSanityWines {
        nodes {
          categories {
            _key
            name
            items {
              _key
              name
              origin
              memberPrice {
                bottle
                glass1
                glass2
              }
              nonMemberPrice {
                bottle
                glass1
                glass2
              }
            }
          }
        }
      }
    }
  `);

  const wines = winesQuery.allSanityWines?.nodes[0]?.categories;

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleChange = (index) => {
    setActiveIndex(index);
    setMenuOpen(false);
  };
  return (
    <div
      id="wine_menu"
      className="relative w-full py-12 mx-auto mb-12 max-w-screen-2xl scroll-margin-top-24"
    >
      <BackgroundTexture />
      <div className="relative w-full max-w-6xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <Tabs
          index={activeIndex}
          onChange={(index) => handleChange(index)}
          className="border border-black"
        >
          <div className="flex items-center justify-between px-4 py-4 border-b border-black sm:px-6 lg:hidden">
            <p className="uppercase heading-3">{wines[activeIndex].name}</p>
            <button
              type="button"
              className="px-4 py-2 bg-transparent border border-blue-dark"
              onClick={() => setMenuOpen((prev) => !prev)}
            >
              Menu
            </button>
          </div>
          <TabList
            className={`lg:grid border-b border-black lg:grid-cols-4  ${
              menuOpen ? 'grid' : 'hidden'
            }`}
          >
            {wines.map((wine: IWine, index) => (
              <MenuTab key={wine._key} index={index} tabsTotal={wines.length}>
                {wine.name}
              </MenuTab>
            ))}
          </TabList>
          <div className="px-4 py-4 border-b border-black sm:px-6 lg:px-8">
            <ul className="grid grid-cols-2 gap-y-4 lg:flex lg:space-x-7">
              <li className="space-x-2 text-lg">
                <strong>M</strong>
                <span>Member</span>
              </li>
              <li className="space-x-2 text-lg">
                <strong>NM</strong>
                <span>Non Member</span>
              </li>
              <li className="col-span-2 text-lg lg:col-span-1">
                <strong>10% surcharge on Public Holidays</strong>
              </li>
            </ul>
          </div>
          <TabPanels>
            {wines.map((wine: IWine) => (
              <TabPanel key={wine._key} className="px-4 py-4 sm:px-6 lg:px-8">
                <ul className="space-y-8">
                  {wine.items.map((item) => (
                    <li
                      className="grid text-lg gap-x-4 md:grid-cols-5"
                      key={item._key}
                    >
                      <div className="col-span-3">
                        <p>
                          <strong>{item.name}</strong>
                        </p>
                        <p>{item.origin}</p>
                      </div>
                      <dl className="flex flex-wrap col-span-2">
                        <div className="flex mr-4 space-x-2 whitespace-nowrap xs:whitespace-normal">
                          <dt className="inline font-semibold">
                            <abbr
                              title="Member"
                              style={{ textDecoration: 'none' }}
                            >
                              M
                            </abbr>
                            :
                          </dt>
                          <dd className="inline-block space-x-4">
                            <dl className="inline-block space-x-4">
                              {item.memberPrice.glass1 ? (
                                <div className="inline-block">
                                  <dt className="inline">
                                    {item.memberPrice.glass1}
                                  </dt>{' '}
                                  {/* <dd className="inline font-semibold">
                                    ${item.memberPrice.glass}
                                  </dd> */}
                                </div>
                              ) : null}
                              {item.memberPrice.glass2 ? (
                                <div className="inline-block">
                                  <dt className="inline">
                                    {item.memberPrice.glass2}
                                  </dt>{' '}
                                  {/* <dd className="inline font-semibold">
                                    ${item.memberPrice.glass}
                                  </dd> */}
                                </div>
                              ) : null}
                              {item.memberPrice.bottle ? (
                                <div className="inline-block">
                                  <dt className="inline">
                                    {item.memberPrice.bottle}
                                  </dt>{' '}
                                  {/* <dd className="inline font-semibold">
                                    ${item.memberPrice.bottle}
                                  </dd> */}
                                </div>
                              ) : null}
                            </dl>
                          </dd>
                        </div>
                        <div className="flex space-x-2 whitespace-nowrap xs:whitespace-normal">
                          <dt className="inline font-semibold">
                            <abbr
                              title="Non Member"
                              style={{ textDecoration: 'none' }}
                            >
                              NM
                            </abbr>
                            :
                          </dt>
                          <dd className="inline-block space-x-4">
                            <dl className="inline-block space-x-4">
                              {item.nonMemberPrice.glass1 ? (
                                <div className="inline-block">
                                  <dt className="inline">
                                    {item.nonMemberPrice.glass1}
                                  </dt>{' '}
                                  {/* <dd className="inline font-semibold">
                                    ${item.nonMemberPrice.glass1}
                                  </dd> */}
                                </div>
                              ) : null}
                              {item.nonMemberPrice.glass2 ? (
                                <div className="inline-block">
                                  <dt className="inline">
                                    {item.nonMemberPrice.glass2}
                                  </dt>{' '}
                                  {/* <dd className="inline font-semibold">
                                    ${item.nonMemberPrice.glass1}
                                  </dd> */}
                                </div>
                              ) : null}
                              {item.nonMemberPrice.bottle ? (
                                <div className="inline-block">
                                  <dt className="inline">
                                    {item.nonMemberPrice.bottle}
                                  </dt>{' '}
                                  {/* <dd className="inline font-semibold">
                                    ${item.nonMemberPrice.bottle}
                                  </dd> */}
                                </div>
                              ) : null}
                            </dl>
                          </dd>
                        </div>
                      </dl>
                    </li>
                  ))}
                </ul>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
}

function MenuTab(props): React.ReactElement {
  const { index, tabsTotal, isSelected, children } = props;

  return (
    <Tab
      className={`px-4 py-5 font-semibold uppercase lg:border-b-0 text-left border-black heading-3 ${
        (index as number) + 1 !== tabsTotal ? 'lg:border-r border-b' : ''
      } ${isSelected ? 'bg-blue-dark text-white' : ''} `}
    >
      {children}
    </Tab>
  );
}

// (sort: { order: DESC, fields: publishedAt })

export { WinesMenu };
