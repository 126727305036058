import * as React from 'react';

function LawnBowlIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
      <path d="M437.02 74.98C388.667 26.629 324.38 0 256 0S123.332 26.628 74.98 74.98C26.629 123.333 0 187.619 0 256s26.628 132.668 74.98 181.02C123.333 485.371 187.62 512 256 512s132.668-26.628 181.02-74.98C485.371 388.667 512 324.381 512 256s-26.628-132.668-74.98-181.02zM256 492.308C125.699 492.308 19.692 386.3 19.692 256S125.699 19.692 256 19.692 492.308 125.7 492.308 256 386.301 492.308 256 492.308z" />
      <path d="M435.265 152.903a5.968 5.968 0 00-.147-.266c-2.628-4.614-8.469-6.325-13.187-3.81-4.798 2.559-6.614 8.524-4.054 13.322a9.847 9.847 0 1017.388-9.246zm-21.853-30.979C374.028 75.727 316.653 49.231 256 49.231c-5.437 0-9.846 4.408-9.846 9.846s4.409 9.846 9.846 9.846c54.877 0 106.789 23.974 142.427 65.776a9.827 9.827 0 007.498 3.458 9.808 9.808 0 006.382-2.352c4.138-3.528 4.633-9.743 1.105-13.881z" />
    </svg>
  );
}

export { LawnBowlIcon };
