import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useGraphQL } from '../hooks/use-graphql';

interface IBoardOfDirectors {
  children: React.ReactNode;
  isReversed?: boolean;
}

function BoardOfDirectors({
  children,
  isReversed = true,
}: IBoardOfDirectors): React.ReactElement {
  return (
    <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
      <article className="grid gap-8 lg:grid-cols-5">
        {!isReversed && <Images />}
        <div className="flex flex-col justify-center lg:col-span-2">
          {children}
        </div>
        {isReversed && <Images />}
      </article>
    </div>
  );
}

function Images(): React.ReactElement {
  const { directorsImage } = useGraphQL();
  return (
    <div className="lg:col-span-3">
      <GatsbyImage
        image={directorsImage.childImageSharp.gatsbyImageData}
        className="flex-1"
        alt="board of the directors"
        objectFit="contain"
      />
    </div>
  );
}

export { BoardOfDirectors };
