// Keep all static queries in this file
// Add new queries as necessary
// (you add aliases if you need to access the same node more than once)
// To use:
// import useGraphql from './src/hooks/use-graphql.js'
// const { site } = useGraphql();
// return <h1>{site.siteMetadata.title}</h1>;

import { graphql, useStaticQuery } from 'gatsby';

export const useGraphQLAbout = () =>
  useStaticQuery(
    graphql`
      {
        clubGrants: file(relativePath: { eq: "club-grants.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        newImage: file(relativePath: { eq: "new-image.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        clubGrants1: file(relativePath: { eq: "cancer-trust.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        clubGrants2: file(relativePath: { eq: "autism-swim.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        clubPolicy: file(relativePath: { eq: "club-policy2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whereItBegan1: file(relativePath: { eq: "where-it-began-1.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whereItBegan2: file(relativePath: { eq: "where-it-began-2.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whereItBegan3: file(relativePath: { eq: "where-it-began-3.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whereItBegan4: file(relativePath: { eq: "where-it-began-4.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        whereItBegan5: file(relativePath: { eq: "where-it-began-5.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
