import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { HiChevronDown } from 'react-icons/hi';

// eslint-disable-next-line sonarjs/cognitive-complexity
function DriftMenu(): React.ReactElement {
  const { allSanityDrift } = useStaticQuery(graphql`
    {
      allSanityDrift {
        nodes {
          categories {
            _key
            name
            collections {
              _key
              name
              description
              items {
                _key
                name
                description
                vegetarian
                glutenFree
                memberPrice
                nonMemberPrice
                variations {
                  _key
                  name
                  memberPrice
                  nonMemberPrice
                }
                addOns {
                  _key
                  name
                  price
                }
                petite {
                  enabled
                  memberPrice
                  nonMemberPrice
                }
              }
            }
          }
        }
      }
    }
  `);

  const menuItems = allSanityDrift?.nodes[0];

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleChange = (index) => {
    setActiveIndex(index);
    setMenuOpen(false);
  };

  const [indices, setIndices] = React.useState([0]);
  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  return (
    <article className="relative w-full py-12 mx-auto max-w-screen-2xl">
      <div className="relative w-full max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        <Tabs
          index={activeIndex}
          onChange={(index) => handleChange(index)}
          className="border border-black"
        >
          <div className="flex items-center justify-between px-4 py-4 border-b border-black sm:px-6 lg:hidden">
            <p className="uppercase heading-3">
              {menuItems.categories[activeIndex].name}
            </p>
            <button
              type="button"
              className="px-4 py-2 bg-transparent border border-blue-dark"
              onClick={() => setMenuOpen((prev) => !prev)}
            >
              Menu
            </button>
          </div>
          <TabList
            className={`lg:grid border-b border-black lg:grid-cols-3  ${
              menuOpen ? 'grid' : 'hidden'
            }`}
          >
            {menuItems.categories.map((category, index) => (
              <MenuTab
                key={category._key}
                index={index}
                tabsTotal={menuItems.categories.length}
              >
                {category.name}
              </MenuTab>
            ))}
          </TabList>
          <div className="px-4 py-4 border-b border-black sm:px-6 lg:px-8">
            <ul className="grid grid-cols-2 gap-y-4 lg:flex lg:space-x-7">
              <li className="flex flex-col text-lg lg:flex-row">
                <strong className="lg:mr-2">(GF)</strong> Gluten Free
              </li>
              <li className="flex flex-col text-lg lg:flex-row">
                <strong className="lg:mr-2">(V)</strong> Vegetarian
              </li>
              <li className="flex flex-col text-lg lg:flex-row">
                <strong className="lg:mr-2">M</strong> Member
              </li>
              <li className="flex flex-col text-lg lg:flex-row">
                <strong className="lg:mr-2">NM</strong> Non Member
              </li>
              <li className="col-span-2 text-lg lg:col-span-1">
                <strong>10% surcharge on Public Holidays</strong>
              </li>
            </ul>
          </div>
          <TabPanels>
            {menuItems.categories.map((category) => (
              <TabPanel key={category._key}>
                <Accordion index={indices} onChange={toggleItem}>
                  {category.collections.map((collection, index) => (
                    <AccordionItem
                      key={collection._key}
                      className={`${
                        category.collections.length !== (index as number) + 1
                          ? 'border-b border-black'
                          : ''
                      }`}
                    >
                      <AccordionButton className="flex items-center justify-between w-full px-4 py-4 text-left sm:px-6 lg:px-8 heading-3">
                        {collection.name}
                        <HiChevronDown
                          className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                            indices.includes(index) ? 'rotate-90' : ''
                          } `}
                        />
                      </AccordionButton>
                      <AccordionPanel className="px-4 pb-4 mt-2 sm:px-6 lg:px-8">
                        <p className="text-lg italic">
                          {collection.description}
                        </p>
                        <ul className="grid mt-4 lg:grid-cols-2 gap-y-8 gap-x-12">
                          {collection.items.map((item) => (
                            <li className="text-lg" key={item._key}>
                              <p className="font-semibold">
                                {item.name}
                                {item.vegetarian && <>&nbsp;(V)</>}
                                {item.glutenFree && <>&nbsp;(GF)</>}
                              </p>
                              <div>
                                <p>{item.description}</p>
                              </div>

                              {item.variations.length > 0 ? (
                                <ul className="mt-1 space-y-2">
                                  {item.variations.map((variation) => (
                                    <li
                                      key={variation._key}
                                      className="font-semibold"
                                    >
                                      M ${variation.memberPrice} -{' '}
                                      {variation.name}
                                      <br />
                                      NM ${variation.nonMemberPrice} -{' '}
                                      {variation.name}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <ul className="mt-1">
                                  <li className="font-semibold">
                                    M ${item.memberPrice}
                                  </li>
                                  <li className="font-semibold">
                                    NM ${item.nonMemberPrice}
                                  </li>
                                </ul>
                              )}
                              {item.addOns.length > 0 && (
                                <ul>
                                  {item.addOns.map((addOn) => (
                                    <li key={addOn._key}>
                                      <strong>Add</strong> {addOn.name}{' '}
                                      <strong>{addOn.price}</strong>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              {item.petite?.enabled && (
                                <ul className="mt-2">
                                  <li className="italic">Make it petite</li>
                                  <li className="font-semibold">
                                    M ${item.petite.memberPrice}
                                  </li>
                                  <li className="font-semibold">
                                    NM ${item.petite.nonMemberPrice}
                                  </li>
                                </ul>
                              )}
                              <div />
                            </li>
                          ))}
                        </ul>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
    </article>
  );
}

function MenuTab(props): React.ReactElement {
  const { index, tabsTotal, isSelected, children } = props;

  return (
    <Tab
      className={`px-4 py-4 font-semibold text-left uppercase border-black sm:px-6 lg:px-8 lg:border-b-0 heading-3 ${
        (index as number) + 1 !== tabsTotal ? 'lg:border-r border-b' : ''
      } ${isSelected ? 'bg-blue-dark text-white' : ''} `}
    >
      {children}
    </Tab>
  );
}

export { DriftMenu };
