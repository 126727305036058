import { Transition } from '@headlessui/react';
import { Link } from 'gatsby';
import * as React from 'react';
import { HiX } from 'react-icons/hi';
import OutsideClickHandler from 'react-outside-click-handler';

import config from '../../config.json';
import { Logo } from '../svg/logo';

interface IMobileMenu {
  isOpen: boolean;
  close: () => void;
}

function MobileMenu({ isOpen, close }: IMobileMenu): React.ReactElement {
  const ref = React.useRef(null);

  const handleEscape = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    },
    [close]
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          close();
        }
      });
    }
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [close, handleEscape]);

  return (
    <OutsideClickHandler onOutsideClick={close}>
      <Transition
        show={isOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="absolute inset-x-0 top-0 w-full p-2 transition origin-top-right transform md:left-auto xl:hidden md:max-w-prose"
      >
        <div
          ref={ref}
          className="bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50"
        >
          <div className="px-5 pt-5 pb-6">
            <div className="flex items-center justify-between">
              <Link to="/" className="block">
                <div className="sr-only">{config.title}</div>
                <Logo className="w-auto h-12" />
              </Link>
              <div className="-mr-2">
                <button
                  type="button"
                  onClick={close}
                  className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset"
                >
                  <span className="sr-only">Close menu</span>
                  <HiX aria-hidden className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
          <div className="px-5 py-6 space-y-6">
            <div className="grid grid-cols-2 gap-y-1 gap-x-8">
              {config.siteNavigation.map((menuItem) => (
                <MenuItem key={menuItem.label} menuItem={menuItem} />
              ))}
            </div>
            <div className="space-y-4">
              <a
                href={`tel:${config.phone}`}
                className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white uppercase transition duration-150 ease-in-out border border-transparent bg-blue-dark hover:bg-black"
              >
                Call us on {config.phone}
              </a>

              <a
                href="https://bookings.nowbookit.com/?accountid=bb2dc809-4b9c-4bff-b262-c0ba1109cb80&venueid=4012&theme=light&colors=hex,0b2e4a,a42331"
                className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white uppercase transition duration-150 ease-in-out bg-red-800 border border-transparent hover:bg-black "
                target="_blank"
                rel="nofollow"
              >
                Book Table Now
              </a>
            </div>
          </div>
        </div>
      </Transition>
    </OutsideClickHandler>
  );
}

function MenuItem({ menuItem }) {
  const { slug, label, submenu } = menuItem;
  if (submenu) {
    return (
      <>
        <Link
          key={slug}
          to={slug}
          className="px-4 py-2 -mx-4 text-base font-medium transition duration-150 ease-in-out text-blue-dark hover:text-gray-700 hover:bg-gray-100"
        >
          {label}
        </Link>
        {submenu.map((sub) => (
          <MenuItem key={sub.label} menuItem={sub} />
        ))}
      </>
    );
  }
  return (
    <Link
      key={slug}
      to={slug}
      className="px-4 py-2 -mx-4 text-base font-medium transition duration-150 ease-in-out text-blue-dark hover:text-gray-700 hover:bg-gray-100"
    >
      {label}
    </Link>
  );
}

export { MobileMenu };
