import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from 'gatsby-plugin-image';
import * as React from 'react';
import { useGraphQL } from '../hooks';

type SideImageProps = {
  image: IGatsbyImageData;
  altText?: string;
  objectFit?: React.CSSProperties['objectFit'];
};

function SideImage({
  image,
  altText = '',
  objectFit,
  secondImage,
  showSecondImage = false,
}) {
  const { secondBanner } = useGraphQL();
  return showSecondImage ? (
    <>
      <div className="aspect-w-16 aspect-h-9 lg:aspect-w-4 lg:aspect-h-3">
        <div className="flex">
          <GatsbyImage
            image={image}
            objectFit={objectFit}
            className="flex-1"
            alt={altText}
          />
        </div>
      </div>
      <div className="aspect-w-16 aspect-h-9 lg:aspect-w-4 lg:aspect-h-3">
        <div className="flex">
          <GatsbyImage
            image={secondImage}
            objectFit={objectFit}
            className="flex-1"
            alt={altText}
          />
        </div>
      </div>
    </>
  ) : (
    <div className="aspect-w-16 aspect-h-9 lg:aspect-w-4 lg:aspect-h-3">
      <div className="flex">
        <GatsbyImage
          image={image}
          objectFit={objectFit}
          className="flex-1"
          alt={altText}
        />
      </div>
    </div>
  );
}

export { SideImage };
