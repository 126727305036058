/* eslint-disable sonarjs/no-duplicate-string */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as React from 'react';
import { HiChevronDown } from 'react-icons/hi';

import { BackgroundTexture } from './background-texture';

function Content({ day }) {
  return (
    <div className="grid gap-12 mt-12 lg:grid-cols-3">
      {day.map((e) => {
        const imageData = getGatsbyImageData(
          e.image.asset,
          { width: 750, placeholder: 'dominantColor' },
          { projectId: 'q45hh4q1', dataset: 'production' }
        );
        return (
          <article key={e.image.asset._id} className="relative mb-0 xs:mb-6">
            <div className="aspect-w-4 aspect-h-3 bg-blue-dark">
              <div className="flex">
                <GatsbyImage
                  image={imageData as IGatsbyImageData}
                  className="flex-1"
                  alt=""
                />
              </div>
            </div>
            <h3 className="mt-6 heading-3">{e.heading}</h3>
            <p>{e.body}</p>
          </article>
        );
      })}
    </div>
  );
}
function WhatsOnAccordion() {
  const whatsOnQuery = useStaticQuery(graphql`
    {
      allSanityPromotionSchedule {
        nodes {
          monday {
            _key
            image {
              asset {
                _id
              }
            }
            body
            heading
          }
          tuesday {
            _key
            body
            heading
            image {
              asset {
                _id
              }
            }
          }
          wednesday {
            _key
            body
            heading
            image {
              asset {
                _id
              }
            }
          }
          thursday {
            _key
            body
            heading
            image {
              asset {
                _id
              }
            }
          }
          friday {
            _key
            body
            heading
            image {
              asset {
                _id
              }
            }
          }
          saturday {
            _key
            body
            heading
            image {
              asset {
                _id
              }
            }
          }
          sunday {
            _key
            body
            heading
            image {
              asset {
                _id
              }
            }
          }
        }
      }
    }
  `);

  const [indices, setIndices] = React.useState([0]);
  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  return (
    <div className="relative w-full px-4 py-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative w-full max-w-6xl mx-auto mt-12 border border-black">
        <Accordion index={indices} onChange={toggleItem}>
          <AccordionItem className="border-b border-black">
            <h3>
              <AccordionButton className="flex items-center justify-between w-full px-4 py-4 font-semibold text-left uppercase heading-3 sm:px-6 lg:px-8">
                Monday
                <HiChevronDown
                  className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                    indices.includes(0) ? 'rotate-90' : ''
                  } `}
                />
              </AccordionButton>
            </h3>
            <AccordionPanel className="px-4 py-4 sm:px-6 lg:px-8">
              <Content
                day={whatsOnQuery.allSanityPromotionSchedule.nodes[0].monday}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem className="border-b border-black">
            <h3>
              <AccordionButton className="flex items-center justify-between w-full px-4 py-4 font-semibold text-left uppercase heading-3 sm:px-6 lg:px-8">
                Tuesday
                <HiChevronDown
                  className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                    indices.includes(1) ? 'rotate-90' : ''
                  } `}
                />
              </AccordionButton>
            </h3>
            <AccordionPanel className="px-4 py-4 sm:px-6 lg:px-8">
              <Content
                day={whatsOnQuery.allSanityPromotionSchedule.nodes[0].tuesday}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem className="border-b border-black">
            <h3>
              <AccordionButton className="flex items-center justify-between w-full px-4 py-4 font-semibold text-left uppercase heading-3 sm:px-6 lg:px-8">
                Wednesday
                <HiChevronDown
                  className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                    indices.includes(2) ? 'rotate-90' : ''
                  } `}
                />
              </AccordionButton>
            </h3>
            <AccordionPanel className="px-4 py-4 sm:px-6 lg:px-8">
              <Content
                day={whatsOnQuery.allSanityPromotionSchedule.nodes[0].wednesday}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem className="border-b border-black">
            <h3>
              <AccordionButton className="flex items-center justify-between w-full px-4 py-4 font-semibold text-left uppercase heading-3 sm:px-6 lg:px-8">
                Thursday
                <HiChevronDown
                  className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                    indices.includes(3) ? 'rotate-90' : ''
                  } `}
                />
              </AccordionButton>
            </h3>
            <AccordionPanel className="px-4 py-4 sm:px-6 lg:px-8">
              <Content
                day={whatsOnQuery.allSanityPromotionSchedule.nodes[0].thursday}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem className="border-b border-black">
            <h3>
              <AccordionButton className="flex items-center justify-between w-full px-4 py-4 font-semibold text-left uppercase heading-3 sm:px-6 lg:px-8">
                Friday
                <HiChevronDown
                  className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                    indices.includes(4) ? 'rotate-90' : ''
                  } `}
                />
              </AccordionButton>
            </h3>
            <AccordionPanel className="px-4 py-4 sm:px-6 lg:px-8">
              <Content
                day={whatsOnQuery.allSanityPromotionSchedule.nodes[0].friday}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem className="border-b border-black">
            <h3>
              <AccordionButton className="flex items-center justify-between w-full px-4 py-4 font-semibold text-left uppercase heading-3 sm:px-6 lg:px-8">
                Saturday
                <HiChevronDown
                  className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                    indices.includes(5) ? 'rotate-90' : ''
                  } `}
                />
              </AccordionButton>
            </h3>
            <AccordionPanel className="px-4 py-4 sm:px-6 lg:px-8">
              <Content
                day={whatsOnQuery.allSanityPromotionSchedule.nodes[0].saturday}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h3>
              <AccordionButton className="flex items-center justify-between w-full px-4 py-4 font-semibold text-left uppercase heading-3 sm:px-6 lg:px-8">
                Sunday
                <HiChevronDown
                  className={`transition-transform h-6 w-6 ease-in-out duration-300 transform ${
                    indices.includes(6) ? 'rotate-90' : ''
                  } `}
                />
              </AccordionButton>
            </h3>
            <AccordionPanel className="px-4 py-4 sm:px-6 lg:px-8">
              <Content
                day={whatsOnQuery.allSanityPromotionSchedule.nodes[0].sunday}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}

export { WhatsOnAccordion };
