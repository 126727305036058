import * as React from 'react';

interface IHeadingWithIcon {
  children: React.ReactNode;
}

function HeadingWithIcon({ children }: IHeadingWithIcon): React.ReactElement {
  return (
    <div className="flex items-center space-x-6 text-blue-dark">{children}</div>
  );
}

function Heading({ children, small = false }) {
  return (
    <h2
      className={`${
        small ? 'heading-3 xs:heading-2 ' : 'heading-2 '
      } heading-accent`}
    >
      {children}
    </h2>
  );
}

function Icon({ children, hidden = false }) {
  return (
    <div
      className={`${
        hidden ? 'hidden xs:block ' : ''
      }w-full max-w-[6rem] max-h-[6rem] transform -translate-y-2`}
    >
      {children}
    </div>
  );
}

HeadingWithIcon.Heading = Heading;
HeadingWithIcon.Icon = Icon;

export { HeadingWithIcon };
